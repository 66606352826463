<template>
  <v-row no-gutters class="background px-6" align="center">
    <v-col :cols="tabCols" xs="4" sm="10" md="10">
      <v-tabs v-model="selectedTab" :height="60" background-color="background" show-arrows hide-slider center-active>
        <template v-for="tab in tabs">
          <div class="d-flex align-center tabSeparator" :key="tab.id">
            <v-tab class="h-100" :ripple="false">
              <template v-if="!hideBudge && tab.budge">
                <v-badge
                  color="red"
                  :content="tab.budge"
                >
                  <span>{{ createFilterName(tab) }}</span>
                </v-badge>
                <v-btn
                  v-if="!tab.isHardcoded"
                  icon
                  x-small
                  class="ml-4"
                  @click.stop="removeTabClick(tab)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <span>{{ createFilterName(tab) }}</span>
                <v-btn
                  v-if="!tab.isHardcoded"
                  icon
                  x-small
                  class="ml-2"
                  @click.stop="removeTabClick(tab)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-tab>
          </div>
        </template>
      </v-tabs>
    </v-col>
    <v-col v-if="actions" xs="5" sm="2" md="2" class="d-flex justify-end">
      <slot name="actions"/>
    </v-col>
    <v-col v-if="addNew" xs="5" sm="2" md="2"  class="d-flex justify-end align-center">
      <span class="text-body-2">{{ $t('filters.addFilter') }} <v-icon x-small>$arrowRight</v-icon></span>
      <v-btn icon tile color="primary" :disabled="$store.getters['market/isMaxNewTab']" @click="addTabClick">
        <v-icon>$filter</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { createFilterName } from '@/utils/market'
import * as types from '@/store/modules/market/market-mutation-types'

export default {
  name: 'Tabs',
  props: {
    addNew: {
      type: Boolean,
      default: false
    },
    hideBudge: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  destroyed () {
    this.$store.dispatch('market/changeTab', 0)
  },
  computed: {
    tabs () {
      return this.$store.getters['market/filters']
    },
    selectedTab: {
      get () {
        return this.$store.getters['market/selectedTab']
      },
      set (value) {
        this.$store.dispatch('market/changeTab', value)
      }
    },
    tabCols () {
      if (this.addNew) {
        return 9
      }

      if (this.actions) {
        return 9
      }

      return 12
    }
  },
  methods: {
    createFilterName,
    addTabClick () {
      this.$store.commit(`market/${types.ADD_FILTER}`)
      this.$emit('add-tab')
      this.selectedTab = this.tabs.length - 1
    },
    async removeTabClick (tab) {
      this.$store.commit(`market/${types.SET_SELECTED_TAB}`, 0)
      if (tab.newTab) {
        this.$store.commit(`market/${types.REMOVE_FILTER}`, tab.id)
      } else {
        await this.$store.dispatch('market/removeFilter', tab.id)
      }
      await this.$store.dispatch('market/fetchItems')
    }
  }
}
</script>

<style scoped>
.v-tab::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.v-tab:hover {
  color: var(--v-primary-base) !important;
  text-shadow: 0 0 .7px var(--v-primary-base), 0 0 .7px var(--v-primary-base);
}

.v-tab--active:hover,
.v-tab--active {
  font-weight: bold;
  color: var(--v-primary-base) !important;
  text-shadow: none;
}

.tabSeparator {
  border-left: solid 1px var(--v-separator-base);
  margin: 10px 0;
}

.tabSeparator:last-child {
  border-right: solid 1px var(--v-separator-base);
  margin: 10px 0;
}
</style>
