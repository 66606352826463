<template>
  <span @click.stop="isOpen = true" class="pointer" style="position: relative">
    <v-hover v-slot="{ hover }">
      <v-icon :color="hover ? 'primary' : 'lightText'">{{ phone ? '$phone' : '$mail' }}</v-icon>
    </v-hover>
    <v-card v-click-outside="close" v-if="isOpen" outlined class="data-table-cell-custom-popup-container pa-0 mt-1">
      <v-card-title class="pa-0">
        <v-spacer />
        <v-icon icon @click.stop="close">$closeIcon</v-icon>
      </v-card-title>
      <v-card-text class="text-center">
        <a v-if="email" :href="'mailto:'+ email+'?subject='+getSubject(subject)" class="text-decoration-none">{{ email }}</a>
        <a v-if="phone" :href="'tel:'+ phone" class="text-decoration-none">{{ phone }}</a>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import Services from '@/services'
export default {
  props: {
    email: {
      type: String
    },
    phone: {
      type: String
    },
    subject: {
      type: Object
    },
    clickType: {
      type: Number
    },
    orderId: {
      type: String
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    getSubject (item) {
      if (!item) return 'Spedimo'

      let from = ''
      if (item.loadingPlaces[0]) {
        from = item.loadingPlaces[0].country.toUpperCase()
        from += item.loadingPlaces[0].place ? item.loadingPlaces[0].place.postalCode : item.loadingPlaces[0].postalCode
      } else {
        from = this.$t('myVehicle.anyDestination')
      }

      let to = ''
      if (item.unloadingPlaces[0]) {
        to = item.unloadingPlaces[0].country.toUpperCase()
        to += item.unloadingPlaces[0].place ? item.unloadingPlaces[0].place.postalCode : item.unloadingPlaces[0].postalCode
      } else {
        to = this.$t('myVehicle.anyDestination')
      }

      return `Spedimo ${from} - ${to}`
    },
    close () {
      this.isOpen = false
    },
    async track () {
      const data = {
        orderId: this.orderId,
        clickType: this.clickType
      }

      await Services.post('/order/userorderclickstatistic', data)
    }
  },
  watch: {
    isOpen: function (val) {
      if (val) {
        this.track()
      }
    }
  }
}
</script>
<style scoped>
 .data-table-cell-custom-popup-container {
   cursor: default;
   position: absolute;
   min-width: 220px;
   right: 0;
   top: -22px;
   z-index: 5;
 }
</style>
