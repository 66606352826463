<template>
<div class="my-vehicles">
    <v-card class="pa-0 pb-10" tile>
      <Loader v-if="loading" />
      <template v-else>
        <v-card-title>
          <h4 class="primary--text">
            {{ $t('freightList.title') }}
          </h4>
          <v-spacer />
          <v-btn color="primary" outlined :class="{ 'mr-4': !$vuetify.breakpoint.xsOnly, 'mb-2': $vuetify.breakpoint.smAndDown }"
            @click="$router.push({ name: 'AddVehicle' })">
            {{ $t("myVehicle.add") }}
          </v-btn>
          <v-btn color="primary" outlined
            @click="$router.push({ name: 'AddFreight' })">
            {{ $t("myVehicle.addFracht") }}
          </v-btn>
        </v-card-title>
        <Tabs class="mb-4" actions>
          <template #actions>
            <v-checkbox v-if="myFreightSentMenu || myFreightSentArchiveMenu"
              v-model="filters.onlyMine.value" @change="toggleOnlyMain">
              <template #label>
                <span>{{ $t('freightList.showOnlyMain') }}</span>
              </template>
            ></v-checkbox>
            <v-checkbox v-else
              v-model="onlyMineDeal" @change="toggleOnlyMain">
              <template #label>
                <span>{{ $t('freightList.showOnlyMain') }}</span>
              </template>
            </v-checkbox>
          </template>
        </Tabs>
        <FrachtsFilter/>
        <Loader v-if="$store.getters['market/loading']" />
        <real-time-data-table
          v-else
          ref="dataTable"
          :headers="headers"
          endpoint="/FreightOrder/GetFiltered"
          :item-class="itemRowBackground"
          :sort-by="['publishDate']"
          :sort-desc="[true]"
          @click:row="openDetails($event)"
        >
          <template v-slot:item.state="{ item }">
            <span class="pl-5">{{ getState(item.state) }}</span>
          </template>
          <template v-slot:item.loadingPlaces="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex align-center">
                <CountryFlag :country='item.loadingPlaces[0].country' class="mr-1"/>
                <div class="d-flex flex-column">
                  <span>
                    {{ item.loadingPlaces[0].country.toUpperCase() }}, <span>{{ item.loadingPlaces[0].place.postalCode }}</span> <br/>
                    {{ item.loadingPlaces[0].place.city }}
                  </span>
                  <div class="lightText--text">
                    <span>{{ item.loadingPlaces[0].date | dateFormat }} </span>
                    <span v-if="item.loadingPlaces[0].from && item.loadingPlaces[0].to">{{ item.loadingPlaces[0].from }} - {{ item.loadingPlaces[0].to }}</span>
                  </div>
                  <div v-if="item.loadingDistanceFromFilter">
                    <span>{{ item.loadingDistanceFromFilter }}</span>
                  </div>
                </div>
              </div>
              <v-tooltip v-if="item.loadingPlaces.length > 1" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="more-data ml-2"> +{{ item.loadingPlaces.length - 1 }} </div>
                </template>
                <div v-for="(location, index) in item.loadingPlaces" :key="index">
                  <div v-if="index !== 0" class="d-flex align-center" style="line-height: 1.25">
                    <CountryFlag :country="location.country" class="mr-1" />
                    <div class="d-flex flex-column">
                      <span>{{ location.country.toUpperCase() }}, {{ location.place.postalCode }} {{ location.place.city }}</span>
                      <span>{{ location.date | dateFormat }}<span v-if="location.from && location.to"> {{ location.from }} - {{ location.to }}</span></span>
                    </div>
                  </div>
                </div>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.unloadingPlaces="{ item }">
            <div v-if="item.unloadingPlaces.length > 0" class="d-flex align-center">
              <div class="d-flex align-center">
                <CountryFlag :country='item.unloadingPlaces[0].country' class="mr-1"/>
                <div class="d-flex flex-column">
                  <div>
                    <span>
                      {{ item.unloadingPlaces[0].country.toUpperCase() }}, {{ item.unloadingPlaces[0].place.postalCode }}<br/>
                      {{ item.unloadingPlaces[0].place.city }}
                    </span>
                    <div class="lightText--text">
                      <span>{{ item.unloadingPlaces[0].date | dateFormat }} </span>
                      <span v-if="item.unloadingPlaces[0].from && item.unloadingPlaces[0].to">{{ item.unloadingPlaces[0].from }} - {{ item.unloadingPlaces[0].to }}</span>
                    </div>
                    <div v-if="item.unloadingDistanceFromFilter">
                      <span>{{ item.unloadingDistanceFromFilter }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <v-tooltip v-if="item.unloadingPlaces.length > 1" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="more-data ml-2">+{{ item.unloadingPlaces.length - 1 }}</div>
                </template>
                <div v-for="(location, index) in item.unloadingPlaces" :key="index">
                  <div v-if="index !== 0" class="d-flex align-center" style="line-height: 1.25">
                    <CountryFlag :country="location.country" class="mr-1" />
                    <div class="d-flex flex-column">
                      <span>{{ location.country.toUpperCase() }}, {{ location.place.postalCode }} {{ location.place.city }}</span>
                      <span>{{ location.date | dateFormat }} <span v-if="location.from && location.to">{{ location.from }} - {{ location.to }}</span></span>
                    </div>
                  </div>
                </div>
              </v-tooltip>
            </div>
            <div v-else class="ml-2">
              {{ $t('myVehicle.anyDestination') }}
            </div>
          </template>
          <template v-slot:item.freightDetails="{ item }">
            <div class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <template v-if="item.type.length === 1" >
                      <DisplayIcon v-if="item.type[0].icon" :icon-name="item.type[0].icon" size="26" />
                      <v-icon v-else>$truck</v-icon>
                    </template>
                    <DisplayIcon v-if="item.type.length > 1" :icon-name="'__icon_MANY_TYPES.svg'" size="26" />
                  </div>
                </template>
                <p class="ma-0">
                  <span v-for="(x, index) in item.type" :key="index">{{ x.name }} </span>
                </p>
              </v-tooltip>
              <div class="d-flex flex-column ml-2">
                <p class="mb-0">{{ item.vehicleDetails }}</p>
                <p class="mb-0">{{ item.vehicleDetailsSecondLine }}</p>
              </div>
            </div>
          </template>
          <template v-slot:item.price="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column w-100">
                <p class="mb-0 text-center">{{ item.paymentDetails }}</p>
                <p class="mb-0 text-center">{{ item.paymentDetailsSecondLine }}</p>
              </div>
            </div>
          </template>
          <template v-slot:item.publishDate="{ item }">
            <div class="text-center">
              {{ item.publishDate | dateFormat('DD.MM.YYYY') }} <br/>
              {{ item.publishDate | dateFormat('HH:mm') }}
            </div>
          </template>
          <template v-slot:item.publisher="{ item }">
            <div class="d-flex align-center cursor-pointer">
              <StatusAvatar :userId="item.publisher.id" :size="26" showStatus class="mr-2"></StatusAvatar>
              <div class="d-flex flex-column">
                <div>{{ item.publisher.name }} {{ item.publisher.surname }}</div>
                <v-tooltip bottom min-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="lightText--text" v-bind="attrs" v-on="on">{{ item.publisher.company.name | trimText(20) }}</div>
                  </template>
                  <v-row class="mt-1">
                    <v-col cols="6">
                      <p>{{ item.publisher.company.name }}<br />
                      NIP: {{ item.publisher.company.vatNumber }}</p>
                      <p>{{ $t('offerDetails.address') }}:<br />
                      {{ item.publisher.company.street }}<br />
                      {{ item.publisher.company.zipCode }}, {{ item.publisher.company.city }}</p>
                    </v-col>
                    <v-col cols="6">
                      {{ $t('offerDetails.averageRating') }}:
                      <v-rating length="5" size="16" color="accent" :value="item.publisher.company.averageRating" class="mb-4"></v-rating>
                      {{ $t('offerDetails.paymentRating') }}:
                      <v-rating length="5" size="16" color="accent" :value="item.publisher.company.averagePaymentRating"></v-rating>
                    </v-col>
                  </v-row>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-slot:[`item.chatIcon`]="{ item }">
            <v-hover v-if="$store.getters.userCommonData.id !== item.publisher.id" v-slot="{ hover }">
              <v-icon icon @click.stop="startConversationWithUser(item)" :color="hover ? 'primary' : ''" size="18">$conversation</v-icon>
            </v-hover>
          </template>
          <template v-slot:item.mail="{ item }">
            <DataTablePopupCell v-if="$store.getters.userCommonData.id !== item.publisher.id" :subject="item"
              :email="item.publisher.email" :clickType="ClickEmail" :orderId="item.id">
            </DataTablePopupCell>
          </template>
          <template v-slot:item.phone="{ item }">
            <DataTablePopupCell v-if="$store.getters.userCommonData.id !== item.publisher.id"
              :phone="item.publisher.phone" :clickType="ClickPhone" :orderId="item.id">
            </DataTablePopupCell>
          </template>
          <template v-slot:item.purchaser="{ item }">
            <div v-if="item.purchaser" class="d-flex align-center cursor-pointer">
              <StatusAvatar :userId="item.purchaser.id" :size="26" showStatus class="mr-2"></StatusAvatar>
              <div class="d-flex flex-column">
                <div>{{ item.purchaser.name }} {{ item.purchaser.surname }}</div>
                <v-tooltip bottom min-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="lightText--text" v-bind="attrs" v-on="on">{{ item.purchaser.company.name | trimText(20) }}</div>
                  </template>
                  <v-row class="mt-1">
                    <v-col cols="6">
                      <p>{{ item.purchaser.company.name }}<br />
                      NIP: {{ item.purchaser.company.vatNumber }}</p>
                      <p>{{ $t('offerDetails.address') }}:<br />
                      {{ item.purchaser.company.street }}<br />
                      {{ item.purchaser.company.zipCode }}, {{ item.purchaser.company.city }}</p>
                    </v-col>
                    <v-col cols="6">
                      {{ $t('offerDetails.averageRating') }}:
                      <v-rating length="5" size="16" color="accent" :value="item.purchaser.company.averageRating" class="mb-4"></v-rating>
                      {{ $t('offerDetails.paymentRating') }}:
                      <v-rating length="5" size="16" color="accent" :value="item.purchaser.company.averagePaymentRating"></v-rating>
                    </v-col>
                  </v-row>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-slot:[`item.chatIconPurchaser`]="{ item }">
            <v-hover v-if="item.purchaser && $store.getters.userCommonData.id !== item.purchaser.id" v-slot="{ hover }">
              <v-icon icon @click.stop="startConversationWithPurchaser(item)" :color="hover ? 'primary' : ''" size="18">$conversation</v-icon>
            </v-hover>
          </template>
          <template v-slot:item.mailPurchaser="{ item }">
            <template v-if="item.purchaser">
              <DataTablePopupCell v-if="$store.getters.userCommonData.id !== item.purchaser.id" :orderId="item.id"
                :subject="item" :email="item.purchaser.email" :clickType="ClickEmail" />
            </template>
          </template>
          <template v-slot:item.phonePurchaser="{ item }">
            <template v-if="item.purchaser">
              <DataTablePopupCell v-if="$store.getters.userCommonData.id !== item.purchaser.id" :orderId="item.id"
                :phone="item.purchaser.phone" :clickType="ClickPhone" />
            </template>
          </template>
          <template v-slot:item.private="{ item }">
            <v-icon v-if="item.isPrivate" size="18">$private</v-icon>
          </template>
          <template v-slot:item.hidden="{ item }">
            <v-icon v-if="item.isHidden" size="18">$hidden</v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-icon :color="hover ? 'primary' : 'separator'" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </v-hover>
              </template>
              <v-list class="py-0">
                <v-list-item v-if="myFreightSentMenu" link @click="refreshOrder(item)">
                  <v-list-item-title>
                    {{ $t('marketListOptions.refresh') }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-if="myFreightSentMenu" class="mx-4"></v-divider>
                <v-list-item v-if="myFreightSentMenu || myFreightSentArchiveMenu" link @click="republishOrder(item)">
                  <v-list-item-title>
                    {{ $t('marketListOptions.resend') }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-if="myFreightSentMenu || myFreightSentArchiveMenu" class="mx-4"></v-divider>
                <v-list-item v-if="myFreightSentMenu && (item.state === 0 || item.state === WithOffers)" link @click="archiveOrder(item)">
                  <v-list-item-title>
                    {{ $t('marketListOptions.remove') }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-if="myFreightSentMenu && (item.state === 0 || item.state === WithOffers)" class="mx-4"></v-divider>
                <v-list-item v-if="(myFreightSentMenu || myFreightMenu) && item.state === Draft" link @click="removeDraft(item)">
                  <v-list-item-title>
                    {{ $t('marketListOptions.removeDraft') }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-if="(myFreightSentMenu || myFreightMenu) && item.state === Draft" class="mx-4"></v-divider>
                <v-list-item link @click="openDetails(item)">
                  <v-list-item-title>
                    {{ $t('marketListOptions.details') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary">{{
                $t("users.slotNoData")
              }}</v-btn>
          </template>
        </real-time-data-table>
      </template>
    </v-card>
    <OfferDetailsDialog
      v-if="vehicleId"
      :value="offerDetailsActive"
      :itemId="vehicleId"
      @closed="closeOfferDetailsDialog"
    />
  </div>
</template>

<script>
import RealTimeDataTable from '@/components/RealTimeDataTable'
import StatusAvatar from '@/components/StatusAvatar'
import CountryFlag from 'vue-country-flag'
import Tabs from '@/views/Market/components/Tabs'
import FrachtsFilter from '@/views/Market/components/FrachtsFilter'
import { generateString, getEnumDescription } from '@/utils/helpers'
import * as types from '@/store/modules/market/market-mutation-types'
import { MY_FREIGHT } from '@/types/order-types'
import { marketStart, marketStop } from '@/signalr/market'
import Loader from '@/components/Loader'
import { catchMarketError, convertDataToFilters, convertFilters } from '@/utils/market'
import { mapGetters } from 'vuex'
import { DEALBYUSER, ONLYMINE } from '@/types/condition-types'
import DisplayIcon from '@/components/icons/DisplayIcon'
import Service from '@/services'
import OfferDetailsDialog from './dialogs/OfferDetailsDialog.vue'
import { New, WithOffers, Draft } from '@/types/order-state'
import { startConversation } from '@/signalr/chat'
import DataTablePopupCell from '@/components/DataTablePopupCell'
import { Phone as ClickPhone, Email as ClickEmail, Chat as ClickChat } from '@/types/click-order-types.js'

export default {
  name: 'Frachts',
  components: {
    RealTimeDataTable,
    StatusAvatar,
    CountryFlag,
    Tabs,
    FrachtsFilter,
    Loader,
    DisplayIcon,
    OfferDetailsDialog,
    DataTablePopupCell
  },
  data () {
    return {
      loading: true,
      searchInputValue: '',
      offerDetailsActive: false,
      vehicleId: null,
      New,
      WithOffers,
      Draft,
      tabs: [
        {
          id: generateString(),
          name: generateString(),
          budge: Math.random() < 0.8 ? Math.floor(Math.random() * 100) : null
        },
        {
          id: generateString(),
          name: generateString(),
          budge: Math.random() < 0.8 ? Math.floor(Math.random() * 100) : null
        }
      ],
      filters: {
        dealByUser: {
          type: DEALBYUSER,
          userId: false
        },
        onlyMine: {
          type: ONLYMINE,
          value: false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentTab: 'market/currentTab'
    }),
    orderStatus () {
      return this.$store.getters.enums.orderStatus
    },
    myFreightMenu () {
      const hardcodedKey = this.$store.getters['market/currentTab'].hardcodedKey
      return hardcodedKey === 'MyFreightOffered'
    },
    myFreightArchiveMenu () {
      const hardcodedKey = this.$store.getters['market/currentTab'].hardcodedKey
      return hardcodedKey === 'MyFreightOfferedArchived'
    },
    myFreightSentMenu () {
      const hardcodedKey = this.$store.getters['market/currentTab'].hardcodedKey
      return hardcodedKey === 'MyFreightSent'
    },
    myFreightSentArchiveMenu () {
      const hardcodedKey = this.$store.getters['market/currentTab'].hardcodedKey
      return hardcodedKey === 'MyFreightSentArchived'
    },
    onlyMineDeal: {
      get () {
        return this.filters.dealByUser.userId === this.$store.getters.userCommonData.id
      },
      set (value) {
        if (value) {
          this.filters.dealByUser.userId = this.$store.getters.userCommonData.id
        } else {
          this.filters.dealByUser.userId = null
        }
      }
    },
    headers () {
      const result = [
        {
          text: this.$t('marketTableHeader.load'),
          align: 'start',
          value: 'loadingPlaces',
          sortable: false
        },
        {
          text: this.$t('marketTableHeader.unload'),
          align: 'start',
          value: 'unloadingPlaces',
          sortable: false
        },
        {
          text: this.$t('marketTableHeader.freightDetails'),
          align: 'start',
          value: 'freightDetails',
          sortable: false
        },
        {
          text: this.$t('marketTableHeader.price'),
          align: 'center',
          value: 'price',
          width: '150',
          sortable: false
        },
        {
          text: this.$t('marketTableHeader.publishDate'),
          align: 'center',
          value: 'publishDate'
        },
        {
          text: this.$t('marketTableHeader.publisher'),
          align: 'start',
          value: 'publisher',
          sortable: false
        },
        {
          value: 'chatIcon',
          width: '10',
          sortable: false
        },
        {
          value: 'phone',
          width: '5',
          sortable: false
        },
        {
          value: 'mail',
          width: '5',
          sortable: false
        },
        {
          text: this.$t('marketTableHeader.purchaser'),
          align: 'start',
          value: 'purchaser',
          sortable: false
        },
        {
          value: 'chatIconPurchaser',
          width: '5',
          sortable: false
        },
        {
          value: 'phonePurchaser',
          width: '5',
          sortable: false
        },
        {
          value: 'mailPurchaser',
          width: '5',
          sortable: false
        },
        {
          value: 'private',
          width: '5',
          sortable: false
        },
        {
          value: 'hidden',
          width: '5',
          sortable: false
        },
        {
          text: '',
          align: 'end',
          width: '20',
          value: 'actions',
          sortable: false
        }
      ]

      const hardcodedKey = this.$store.getters['market/currentTab'].hardcodedKey

      if (hardcodedKey === 'MyFreightSent' || hardcodedKey === 'MyFreightSentArchived') {
        return [
          {
            text: this.$t('marketTableHeader.state'),
            align: 'start',
            value: 'state'
          },
          ...result
        ].filter(r => r.value !== 'phone' && r.value !== 'mail')
      } else if (hardcodedKey === 'MyFreightOffered' || hardcodedKey === 'MyFreightOfferedArchived') {
        return result.filter(r => r.value !== 'phonePurchaser' && r.value !== 'mailPurchaser')
      } else {
        return result
          .filter(r => r.value !== 'purchaser')
          .filter(r => r.value !== 'chatIconPurchaser')
          .filter(r => r.value !== 'phonePurchaser')
          .filter(r => r.value !== 'mailPurchaser')
      }
    }
  },
  watch: {
    currentTab () {
      this.filters = convertDataToFilters(this.currentTab)
    }
  },
  beforeCreate () {
    this.$store.commit(`market/${types.SET_ORDER_TYPE}`, MY_FREIGHT)
  },
  async created () {
    this.ClickPhone = ClickPhone
    this.ClickEmail = ClickEmail
    this.ClickChat = ClickChat

    this.filters = convertDataToFilters(this.currentTab)
    try {
      await this.$store.dispatch('market/fetchFilters')
    } catch (e) {
    } finally {
      this.loading = false
    }
  },
  async mounted () {
    await marketStart()
  },
  beforeDestroy () {
    marketStop()
  },
  methods: {
    getState (state) {
      return getEnumDescription(this.orderStatus, state)
    },
    itemRowBackground (item) {
      return !item.checked ? 'bgNew' : 'bgRow'
    },
    async toggleOnlyMain () {
      const filtersData = this.myFreightSentMenu || this.myFreightSentArchiveMenu
        ? this.filters.onlyMine
        : this.filters.dealByUser

      const conditions = {
        filtersData,
        locations: {
          ...this.filters.locations,
          loadingPlaces: this.filters.locations.loadingPlaces.map(i => ({ ...i.value })),
          unloadingPlaces: this.filters.locations.unloadingPlaces.map(i => ({ ...i.value }))
        }
      }
      const filters = convertFilters(this.$store.getters['market/orderType'], conditions)
      await this.$store.dispatch('market/submitFilter', { model: { ...filters } })
    },
    openDetails (item) {
      this.$store.commit('market/MARK_SHOW_OFFER', item)
      this.vehicleId = item.id
      this.offerDetailsActive = true
    },
    closeOfferDetailsDialog () {
      this.offerDetailsActive = false
      this.vehicleId = null
    },
    republishOrder (item) {
      this.$router.push({ name: 'CopyFreight', params: { id: item.id } })
    },
    async refreshOrder (item) {
      try {
        await Service.post('/Order/Refresh', { orderId: item.id })
        await this.$store.dispatch(
          'setSuccessNotification',
          `${this.$t('market.refreshOrder')}`)
      } catch (e) {
        await catchMarketError(e, true)
      }
    },
    async archiveOrder (item) {
      try {
        await Service.post('/Order/Reject', { orderId: item.id })
        await this.$store.dispatch(
          'setSuccessNotification',
          `${this.$t('market.archiveOrder')}`)
      } catch (e) {
        await catchMarketError(e, true)
      }
    },
    async removeDraft (item) {
      try {
        await Service.delete('/FreightOrder/RemoveDraft', { orderId: item.id })
        await this.$store.dispatch(
          'setSuccessNotification',
          `${this.$t('market.removeDraftOrder')}`)
      } catch (e) {
        await catchMarketError(e, true)
      }
    },
    async startConversationWithUser (row) {
      const data = {
        orderId: row.id,
        clickType: ClickChat
      }

      await Service.post('/order/userorderclickstatistic', data)
      startConversation(row.publisher.id, row.id)
    },
    async startConversationWithPurchaser (row) {
      const data = {
        orderId: row.id,
        clickType: ClickChat
      }

      await Service.post('/order/userorderclickstatistic', data)
      if (row.purchaser) {
        startConversation(row.purchaser.id, row.id)
      }
    }
  }
}
</script>
