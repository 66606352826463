<template>
  <v-dialog :value="value" :fullscreen="$vuetify.breakpoint.mobile" max-width="600" scrollable persistent>
    <v-card outlined>
      <v-card-title class="mb-4 py-2 pr-2">
        <h4 class="primary--text">{{ $t('filters.frachtsDialog.title') }}</h4>
        <v-spacer />
        <v-btn icon @click="$emit('input', false)">
          <v-icon>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <h3>{{ $t('filters.frachtsDialog.details') }}</h3>
        <v-row class="mt-1 mb-4" dense>
          <v-col cols="12" md="6">
            <label for="cooperationStatus">{{ $t('filters.frachtsDialog.cooperationStatus') }}</label>
            <v-select
              v-model="filters.companyPayerStatus.value"
              id="cooperationStatus"
              :items="cooperationStatuses"
              item-text="description"
              item-value="id"
              outlined
              required
              validate-on-blur
              dense
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <label for="datePayment">{{ $t('filters.frachtsDialog.datePayment') }}</label>
            <v-select
              v-model="filters.paymentType.value"
              id="datePayment"
              :items="paymentTypes"
              item-text="description"
              item-value="id"
              outlined
              required
              validate-on-blur
              dense
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <label for="bodyType">{{ $t('filters.frachtsDialog.bodyType') }}</label>
            <v-select
              v-model="filters.type.value"
              id="bodyType"
              :items="vehicleTypes"
              item-text="translatedValues[0].name"
              item-value="id"
              outlined
              required
              validate-on-blur
              dense
              single-line
              multiple
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <template v-if="isMyFreightSentOrArchived">
              <label for="company">{{ $t('filters.frachtsDialog.offering') }}</label>
              <v-select
                v-model="filters.dealByCompany.companyId"
                id="company"
                :items="companies"
                item-text="name"
                item-value="id"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details
              ></v-select>
            </template>
            <template v-else>
              <label for="company">{{ $t('filters.frachtsDialog.reporting') }}</label>
              <v-select
                v-model="filters.companyId.value"
                id="company"
                :items="companies"
                item-text="name"
                item-value="id"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details
              ></v-select>
            </template>
          </v-col>
          <v-col cols="12" md="6">
            <label for="freightTypes">{{ $t('filters.frachtsDialog.cargoType') }}</label>
            <v-select
              v-model="filters.vehicleFreightTypes.value"
              id="freightTypes"
              :items="freightTypes"
              item-text="description"
              item-value="id"
              outlined
              required
              validate-on-blur
              dense
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <template v-if="isMyFreightSentOrArchived">
              <label for="employee">{{ $t('filters.frachtsDialog.employee') }}</label>
                <v-select
                  v-model="filters.publisherId.value"
                  id="employee"
                  :items="employees"
                  :item-text="displayUserName"
                  item-value="id"
                  outlined
                  required
                  validate-on-blur
                  dense
                  single-line
                  hide-details
                ></v-select>
            </template>
            <template v-else>
              <label for="employee">{{ $t('filters.frachtsDialog.employee') }}</label>
                <v-select
                  v-model="filters.dealByUser.userId"
                  id="employee"
                  :items="employees"
                  :item-text="displayUserName"
                  item-value="id"
                  outlined
                  required
                  validate-on-blur
                  dense
                  single-line
                  hide-details
                ></v-select>
            </template>
          </v-col>
        </v-row>
        <v-card-actions class="pa-0">
          <v-btn color="primary" outlined min-width="120" @click="$emit('input', false)">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="clearFilters">
            {{ $t('filters.frachtsDialog.clear') }}
          </v-btn>
          <v-btn color="primary" outlined min-width="120" @click="confirm">
            {{ $t('filters.frachtsDialog.showResults') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Service from '@/services'
import { convertFilters } from '@/utils/market'

export default {
  name: 'MoreFrachtFiltersDialog',
  props: {
    value: {
      type: Boolean
    },
    data: {
      type: Object,
      required: true
    },
    currentTabName: null
  },
  data () {
    return {
      vehicleTypes: [],
      companies: [],
      employees: []
    }
  },
  computed: {
    isMyFreightOfferedOrArchived () {
      return this.currentTabName === 'MyFreightOffered' || this.currentTabName === 'MyFreightOfferedArchived'
    },
    isMyFreightSentOrArchived () {
      return this.currentTabName === 'MyFreightSent' || this.currentTabName === 'MyFreightSentArchived'
    },
    filters: {
      get () {
        return this.data
      },
      set () {}
    },
    cooperationStatuses () {
      return this.$store.getters.enums.cooperationStatuses
    },
    paymentTypes () {
      return this.$store.getters.enums.dateOfPayments
    },
    freightTypes () {
      return this.$store.getters.enums.freightTypes
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const { data: vehicleTypes } = await Service.get('Dictionary/GetVehicleTypes')
        this.vehicleTypes = vehicleTypes
        const { data: companies } = await Service.get('Company/GetCompanyNames')
        this.companies = companies
        const { data: employees } = await Service.get('Company/GetCompanyUsers', { companyId: this.$store.getters.userCommonData.companyId })
        this.employees = employees
      } catch (e) {
        console.error(e)
      }
    },
    displayUserName (item) {
      return `${item.name} ${item.surname}`
    },
    async clearFilters () {
      const filters = convertFilters(this.$store.getters['market/orderType'], [])
      await this.$store.dispatch('market/submitFilter', { model: { ...filters } })
    },
    confirm () {
      this.$emit('change-filters', this.filters)
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>

</style>
